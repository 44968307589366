import * as React from 'react'

export const useState = <T>(initialValue?: T) => {
  const ref = React.useRef(true)
  const [value, _setValue] = React.useState<T | undefined>(initialValue)

  React.useEffect(() => {
    return () => {
      ref.current = false
    }
  }, [])

  const setValue = React.useCallback(
    (v: Parameters<typeof _setValue>[0]) => {
      if (!ref.current) return
      _setValue(v)
    },
    [ref]
  )

  return [value, setValue] as const
}
