import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { EnterCode } from './EnterCode'
import { EnterEmail } from './EnterEmail'
import { SetNewPassword } from './SetNewPassword'
import { Success } from './Success'

export const PasswordReset: React.FC = () => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.url}/enter-code`}>
        <EnterCode />
      </Route>

      <Route path={`${match.url}/set-new-password`}>
        <SetNewPassword />
      </Route>

      <Route path={`${match.url}/success`}>
        <Success />
      </Route>

      <Route path={match.url}>
        <EnterEmail />
      </Route>
    </Switch>
  )
}
