export default (url: string, element: HTMLElement): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const script = element.ownerDocument.createElement('script')
    const onLoad = () => resolve()

    script.onload = onLoad
    script.onerror = reject

    script.setAttribute('src', url)
    script.setAttribute('type', 'text/javascript')

    return element.appendChild(script)
  })
}
