export const checkboxAnimation = {
  '0%': {
    transform: 'translate(0,0)',
    border: '0.071rem #bf1843 solid'
  },
  '1.78571%': { transform: 'translate(0.214rem, 0)' },
  '3.57143%': { transform: 'translate(0,0)' },
  '5.35714%': { transform: 'translate(0.214rem, 0)' },
  '7.14286%': { transform: 'translate(0,0)' },
  '8.92857%': { transform: 'translate(0.214rem, 0)' },
  '10.71429%': { transform: 'translate(0, 0)' },
  '100%': { transform: 'translate(0, 0)' }
}

export const fadeInStyle = {
  '0%': {
    height: '0',
    opacity: '0'
  },
  '100%': {
    height: '3.571rem',
    opacity: '1'
  }
}

export const fadeOutStyle = {
  '0%': {
    height: '3.571rem',
    opacity: '1'
  },
  '80%': {
    height: '1.071rem',
    opacity: '1'
  },
  '100%': {
    height: '0',
    opacity: '0'
  }
}
