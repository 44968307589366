import React from 'react'
import { createUseStyles } from 'react-jss'
import defaultImage from '../../assets/images/default-image.jpg'
import { useContext } from '../context'

const useStyles = createUseStyles({
  gameImage: {
    display: 'none',
    width: '25.357rem',
    '@media (min-width: 755px)': {
      display: 'block'
    }
  }
})

export const GameImage: React.FC = () => {
  const { gameImageUrl } = useContext()
  const classes = useStyles()

  return (
    <img className={classes.gameImage} src={gameImageUrl || defaultImage} />
  )
}
