import React from 'react'
import { LocaProvider } from '@gameforge/loca/react'
import { useContext } from '../context'
import { useMessages } from './useMessages'

export const Provider: React.FC = props => {
  const { locale } = useContext()
  const messages = useMessages(locale)

  if (!messages) return null

  return <LocaProvider locale={locale} messages={messages} {...props} />
}
