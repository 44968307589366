import React from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { Loca } from '../../intl'
import { useExternalAuth } from '../Provider'

const useStyles = createUseStyles({
  returnLink: {
    textAlign: 'center'
  }
})

export const ReturnLink: React.FC = () => {
  const classes = useStyles()
  const { origin } = useExternalAuth()
  const hrefs = {
    signup: '/sign-up',
    signin: '/sign-in'
  }

  if (!origin || !hrefs[origin]) return null

  return (
    <Loca
      as="p"
      className={classes.returnLink}
      id="ExternalAuthentication.Back"
      links={{ 'go-back': props => <Link to={hrefs[origin]} {...props} /> }}
      values={{ origin }}
    />
  )
}
