import React, { useEffect, useState } from 'react'
import { FieldError } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { Loca } from '../../intl'

const useStyles = createUseStyles({
  error: {
    color: '#ea1d36',
    marginBottom: `1rem`
  }
})

type Errors = {
  input?: FieldError['type']
  api?: string
}

type Props = {
  error: Errors
  component: 'ResetPassword' | 'TwoFactorAuthentication.Recover'
}

export const Error: React.FC<Props> = props => {
  const classes = useStyles()
  const { input, api } = props.error
  const { component } = props
  const [errorMessage, setErrorMessage] = useState<string>('')

  const errorMap = {
    required: `${component}.RequiredCode`,
    minLength: `${component}.EmptyCode`
  }

  useEffect(() => {
    if (input) setErrorMessage(errorMap[input as keyof typeof errorMap])

    if (api) setErrorMessage(api)
  }, [input, api])

  return errorMessage ? (
    <Loca className={classes.error} id={errorMessage} />
  ) : null
}
