import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { AlreadySignedIn } from './alreadySignedIn'
import { Banned } from './banned'
import { PasswordReset } from './passwordReset'
import { PostAuthentication } from './postAuthentication'
import { SignIn } from './signIn'
import { SignUp } from './signUp'

export const Routes: React.FC = () => (
  <Switch>
    <Route exact path="/">
      <AlreadySignedIn />
    </Route>

    <Route path="/sign-in">
      <SignIn />
    </Route>

    <Route path="/sign-up">
      <SignUp />
    </Route>

    <Route path="/reset-password">
      <PasswordReset />
    </Route>

    <Route path="/banned">
      <Banned />
    </Route>

    <Route path="/post-authentication">
      <PostAuthentication />
    </Route>

    <Redirect to="/sign-up" />
  </Switch>
)
