import React from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { apiClient } from '../../apiClient'
import { Password } from '../../components'
import { Loca } from '../../intl'
import { useState } from '../../utils'
import { useSignIn } from '../signIn/useSignIn'

export const SetNewPassword: React.FC = () => {
  const history = useHistory()
  const location = useLocation<{ email: string; code: string }>()
  const [busy, setBusy] = useState(false)
  const { signIn } = useSignIn(true)
  const { register, handleSubmit, errors } = useForm<{ newPassword: string }>({
    mode: 'onChange'
  })

  const onSubmit = handleSubmit(({ newPassword }) => {
    setBusy(true)

    const { email, code } = location.state

    void apiClient
      .postJson(`/users/${email}/password/${code}`, { newPassword })
      .then(() => signIn({ email, password: newPassword }))
      .then(() =>
        history.push('/reset-password/success', { email, newPassword })
      )
      .catch(() => setBusy(false))
  })

  return (
    <>
      <Loca as="h1" id="PasswordReset.SetPassword.Title" />

      <form onSubmit={onSubmit}>
        <Loca as="p" id="PasswordReset.SetPassword.Description" />

        <label>
          <Loca id="PasswordReset.SetPassword.NewPassword" />

          <Password.Input
            name="newPassword"
            error={errors.newPassword}
            ref={register({
              required: true,
              validate: Password.validate
            })}
            showValidationMessage={true}
          />
        </label>

        <Loca
          as="button"
          type="submit"
          disabled={busy}
          id="PasswordReset.SetPassword.Submit"
        />
      </form>
    </>
  )
}
