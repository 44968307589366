import React from 'react'
import { useHistory } from 'react-router-dom'
import { SupportLink } from '../../components'
import { Loca } from '../../intl'

export const Banned = () => {
  const history = useHistory()

  return (
    <>
      <Loca as="h1" id="Banned.Title" />

      <Loca as="p" id="Banned.BanReason" />

      <Loca
        as="p"
        id="Banned.ContactSupport"
        links={{ support: SupportLink }}
      />

      <a onClick={history.goBack}>
        <Loca id="Generic.SignOut" /> ➔
      </a>
    </>
  )
}
