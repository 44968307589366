import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { RemoveTwoFactorAuth } from './RemoveTwoFactorAuth'
import { SignInForm } from './SignInForm'
import { TwoFactorCode } from './TwoFactorCode'

export const SignIn: React.FC = () => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.url}/enter-code`}>
        <TwoFactorCode />
      </Route>

      <Route path={`${match.url}/remove-two-factor-auth`}>
        <RemoveTwoFactorAuth />
      </Route>

      <Route path={match.url}>
        <SignInForm />
      </Route>
    </Switch>
  )
}
