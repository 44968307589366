import React from 'react'
import { useContext } from '../context'

export const TermsAndConditionsLink: React.FC = props => {
  const { locale } = useContext()

  const localeWithoutDash = locale.replace('-', '')
  const termsAndConditionsUrl = `https://agbserver.gameforge.com/${localeWithoutDash}-Switch-GF-Portal.html`

  return (
    <a {...props} href={termsAndConditionsUrl} target="_blank" rel="noopener" />
  )
}
