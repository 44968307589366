import { Plugin } from '@gameforge/http-client'

declare global {
  interface Window {
    gfChallenge?: (challengeId: string) => Promise<unknown>
  }
}

const loadScript = (url: string): Promise<() => void> =>
  new Promise(resolve => {
    const el = document.body.appendChild(document.createElement('script'))
    el.onload = () => resolve(() => document.body.removeChild(el))
    el.src = url
  })

const challengeHeader = 'gf-challenge-id'

export const interceptChallenges = (): Plugin => {
  let loadingPromise: Promise<void> | undefined

  return client => {
    return {
      transformResponse: async (req, res) => {
        const rawHeader = res.headers.get(challengeHeader)

        if (res.status !== 409 || !rawHeader) return res

        const [challengeId, ...challengeServiceUrlParts] = rawHeader.split(';')
        const challengeServiceUrl = challengeServiceUrlParts.join(';')

        const getChallengePromise = async () => {
          const removeScript = await loadScript(
            `${challengeServiceUrl}/index.js`
          )

          try {
            if (!window.gfChallenge)
              throw new Error('Challenge script not loaded')

            await window.gfChallenge(challengeId)
          } finally {
            removeScript()
          }
        }

        if (!loadingPromise) loadingPromise = getChallengePromise()

        await loadingPromise

        loadingPromise = undefined

        const clonedReq = req.clone()
        clonedReq.headers.set(challengeHeader, challengeId)

        return client.send(clonedReq)
      }
    }
  }
}
