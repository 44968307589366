export const openPopup = (url: string, onClose: () => void) => {
  const options = [
    'resizable=no',
    'menubar=no',
    'toolbar=no',
    'location=no',
    'personalbar=no',
    'status=no',
    'width=400px',
    'height=580px'
  ].join(',')

  const popup = window.open(url, '', options)

  if (!popup) return

  const id = setInterval(() => {
    if (!popup.closed) return

    clearInterval(id)
    onClose()
  }, 500)

  const closePopup = () => {
    clearInterval(id)

    return popup.close()
  }

  return closePopup
}
