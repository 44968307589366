import React from 'react'
import { useForm } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { Link, useLocation } from 'react-router-dom'
import { Password, Form, Email } from '../../components'
import * as ExternalAuth from '../../externalAuth'
import { Loca } from '../../intl'
import { useSignIn } from './useSignIn'

const useStyles = createUseStyles({
  signUpPrompt: {
    textAlign: 'center'
  }
})

export const SignInForm: React.FC = () => {
  const classes = useStyles()
  const location = useLocation<{ email?: string; userExistsError?: boolean }>()
  const userExistsError = location.state?.userExistsError
  const { signIn, busy, error } = useSignIn()
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      email: location.state?.email || process.env.SIGN_IN_EMAIL || '',
      password: process.env.SIGN_IN_PASSWORD || '',
      rememberMe: !!process.env.SIGN_IN_REMEMBER
    }
  })

  return (
    <>
      <Loca as="h1" id="Generic.SignIn" />

      <form onSubmit={handleSubmit(signIn)}>
        <Form.Label
          errors={error || userExistsError}
          errorMessageId={error ? 'SignIn.Error' : 'SignIn.UserExists'}
        >
          <Email.Input
            name="email"
            error={errors.email}
            ref={register({
              required: 'Email.Required',
              validate: Email.validate
            })}
          />
        </Form.Label>

        <label>
          <Loca id="Generic.Password" />
          <Password.Input name="password" ref={register({ required: true })} />
          <small className="note">
            <Loca as={Link} id="SignIn.ResetPassword" to="/reset-password" />
          </small>
        </label>

        <label className="inline">
          <input name="rememberMe" type="checkbox" ref={register} />
          <Loca id="SignIn.RememberMe" />
        </label>

        <Loca as="button" type="submit" disabled={busy} id="Generic.SignIn" />

        <Loca as="div" className="separator" id="SignIn.SignInWith" />

        <ExternalAuth.Buttons origin="signin" />

        <Loca
          as="p"
          className={classes.signUpPrompt}
          id="SignIn.NeedAnAccount"
          links={{ 'sign-up': props => <Link to="/sign-up" {...props} /> }}
        />
      </form>
    </>
  )
}
