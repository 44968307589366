import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Redirect } from 'react-router-dom'
import { useBlackbox } from '../../blackbox'
import { useCountdown } from '../../../utils'
import { apiClient } from '../../apiClient'
import { useContext } from '../../context'
import { Loca } from '../../intl'
import { useSession } from '../useSession'

const useStyles = createUseStyles({
  error: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '5rem',
    '& a': {
      marginTop: '2rem'
    },
    '& span': {
      textAlign: 'center'
    }
  }
})

export const PostAuthentication: React.FC = () => {
  const { locale, close, gameId, gameEnvironmentId } = useContext()
  const getBlackbox = useBlackbox()
  const { session, signOut } = useSession()
  const classes = useStyles()
  const [error, setError] = useState(false)
  const [redirectUrl, setRedirectUrl] = useState<string | undefined>()
  const [gameType, setGameType] = useState<string | undefined>()
  const [countdown, startCountDown] = useCountdown(3)

  useEffect(() => {
    if (!session || countdown > 0) return

    void getBlackbox()
      .then(async blackbox => {
        const res = await apiClient.postJson('/users/me/accounts', {
          gameId,
          gameEnvironmentId,
          locale,
          blackbox
        })

        const body = await res.json<{
          redirectUrl?: string
          gameType?: string
        }>()

        startCountDown()
        setGameType(body.gameType)
        setRedirectUrl(body.redirectUrl)
      })
      .catch(() => setError(true))
  }, [session, close, locale, gameId, gameEnvironmentId])

  useEffect(() => {
    if (!redirectUrl || countdown > 0) return
    close(true, redirectUrl)
  }, [redirectUrl, close, countdown])

  if (session === false) return <Redirect to="/sign-up" />

  return (
    <div className={classes.error}>
      {error ? (
        <>
          <Loca id="PostAuthentication.Error" />

          <a role="link" onClick={signOut}>
            ❮ <Loca id={'Generic.BackSignUp'} />
          </a>
        </>
      ) : (
        <>
          {redirectUrl ? (
            <>
              <Loca
                id="PostAuthentication.Redirect"
                values={{ cooldownSeconds: countdown, gameType }}
              />
              <Loca
                id="PostAuthentication.RedirectLink"
                as="a"
                href={redirectUrl}
                role="link"
                target="_top"
              />
            </>
          ) : (
            <Loca id="PostAuthentication.Loading" />
          )}
        </>
      )}
    </div>
  )
}
