import React, { ContextType } from 'react'
import { createUseStyles } from 'react-jss'
import { BlackboxProvider } from './blackbox'
import { CloseButton, Form, GameImage } from './components'
import { Context } from './context'
import { Event } from './events'
import * as ExternalAuth from './externalAuth'
import { Provider } from './intl'
import { Router } from './Router'
import { Routes } from './routes'
import './fonts'

const useStyles = createUseStyles({
  '@global *': {
    boxSizing: 'border-box'
  },
  '@global html,body,#root': {
    width: '100%',
    height: '100%'
  },
  '@global #root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '22.857rem'
  },
  '@media (max-width: 1320px)': {
    '@global #root': {
      alignItems: 'start'
    }
  },
  ui: {
    minHeight: '47rem',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    position: 'relative'
  }
})

export const UI: React.FC<ContextType<typeof Context>> = props => {
  const classes = useStyles()

  return (
    <Context.Provider value={props}>
      <Event type="GFLandingpageConnectOpen" />

      <div className={classes.ui}>
        <Provider>
          <BlackboxProvider>
            <Form.Area>
              <Router>
                <ExternalAuth.Provider>
                  <Routes />
                </ExternalAuth.Provider>
              </Router>
            </Form.Area>
            <CloseButton />
            <GameImage />
          </BlackboxProvider>
        </Provider>
      </div>
    </Context.Provider>
  )
}
