import React from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { Facebook, Google } from '../icons'
import { useExternalAuth, ContextType } from './Provider'

const useStyles = createUseStyles({
  externalAuthIcons: {
    display: 'flex',
    flexDirection: 'row',
    listStyle: 'none',
    justifyContent: 'center',
    padding: 0,
    '& > li': {
      margin: '0 0.536rem',
      '& svg': {
        width: '2.857rem',
        height: '2.857rem'
      }
    }
  }
})

type Props = { origin: NonNullable<ContextType['origin']> }

export const Buttons: React.FC<Props> = ({ origin }) => {
  const classes = useStyles()
  const { error } = useExternalAuth()

  return (
    <>
      <ul className={classes.externalAuthIcons}>
        <li>
          <Link
            to={{
              pathname: '/external-auth/start/google',
              state: { origin }
            }}
          >
            <Google role="google-button" />
          </Link>
        </li>

        <li>
          <Link
            to={{
              pathname: '/external-auth/start/facebook',
              state: { origin }
            }}
          >
            <Facebook role="facebook-button" />
          </Link>
        </li>
      </ul>

      {error && <p>{error}</p>}
    </>
  )
}
