import { useEffect } from 'react'
import * as types from '../../types'
import { dispatch } from '.'

export type Props<T extends types.Event> = types.Events[T] extends void
  ? { type: T; detail?: types.Events[T] }
  : { type: T; detail: types.Events[T] }

export const Event = <T extends types.Event>({ type, detail }: Props<T>) => {
  useEffect(() => {
    dispatch(...([type, detail] as Parameters<typeof dispatch>))
  }, [])

  return null
}
