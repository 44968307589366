import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { addHeaders } from '@gameforge/http-client/plugins/addHeaders'
import { apiClient } from '../../apiClient'
import { useBlackbox } from '../../blackbox'
import { useContext } from '../../context'
import { ContextType } from '../Provider'

export const useCompleteUser = () => {
  const history = useHistory()
  const { locale } = useContext()
  const getBlackbox = useBlackbox()
  const [persistedEmail, setPersistedEmail] = useState<string>()
  const [token, setToken] = useState<string>()
  const location = useLocation<{ token: string; email?: string }>()

  useEffect(() => {
    setToken(token => token || location.state?.token)
  }, [location.state?.token])

  useEffect(() => {
    setPersistedEmail(email => email || location.state?.email)
  }, [location.state?.email])

  const completeUser: ContextType['completeUser'] = useCallback(
    async ({ email, password }) => {
      if (!token) return Promise.reject(new Error('No token provided'))

      if (email) setPersistedEmail(email)

      const body = {
        blackbox: await getBlackbox(),
        email: email || persistedEmail,
        password,
        locale
      }

      return apiClient
        .use(addHeaders({ authorization: `Bearer ${token}` }))
        .patchJson('/users/me', body)
        .then(() => history.push('/post-authentication'))
    },
    [persistedEmail, locale, token]
  )

  return { completeUser, persistedEmail }
}
