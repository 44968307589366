import React from 'react'
import { createUseStyles } from 'react-jss'
import { useContext } from '../context'
import { Close } from '../icons'

const useStyles = createUseStyles({
  closeButton: {
    right: '0.714rem',
    fill: '#fff',
    width: '1rem',
    height: '1rem',
    position: 'absolute',
    top: '0.714rem',
    cursor: 'pointer',
    '@media (min-width: 755px)': {
      right: '26.071rem'
    }
  }
})

export const CloseButton: React.FC = () => {
  const classes = useStyles()
  const { close } = useContext()

  return (
    <Close
      role="close-button"
      className={classes.closeButton}
      onClick={() => close()}
    />
  )
}
