import React from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import { Event } from '../../events'
import { Loca } from '../../intl'
import { useSession } from '../useSession'

const useStyles = createUseStyles({
  signOutLink: {
    display: 'block',
    textAlign: 'center'
  }
})

export const AlreadySignedIn = () => {
  const history = useHistory()
  const { session, signOut } = useSession()
  const classes = useStyles()

  if (session === undefined) return null
  if (session === false) return <Redirect to="/sign-up" />

  const continueWithCurrentSession = () => history.push('/post-authentication')

  return (
    <>
      <Event type="GFSessionExist" detail={{ userId: session.id }} />

      <Loca as="h1" id="Generic.SignIn" />

      <Loca as="p" id="AlreadySignedIn.Description" />

      <Loca
        as="button"
        id="AlreadySignedIn.Continue"
        type="submit"
        values={{ email: session.email }}
        onClick={continueWithCurrentSession}
      />

      <Loca
        as="a"
        id="Generic.SignOut"
        className={classes.signOutLink}
        onClick={signOut}
      />
    </>
  )
}
