import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Loca } from '../../intl'
import { fadeInStyle, fadeOutStyle } from '../../validation'

const useStyles = createUseStyles({
  '@keyframes fadeInAnimation': fadeInStyle,
  '@keyframes fadeOutAnimation': fadeOutStyle,
  error: {
    width: '100%',
    height: '0',
    bottom: '4.748rem',
    opacity: '0',
    position: 'absolute',
    zIndex: '10',
    backgroundColor: '#bf1843',
    border: '1px solid #bb1740',
    padding: '0.3rem 0.3rem 0 0.3rem',
    '& span': {
      color: '#fff',
      fontSize: '0.857rem'
    }
  },
  fadeOut: {
    animation: '$fadeOutAnimation 0.2s ease-out',
    height: '0',
    opacity: '0',
    zIndex: '-9'
  },
  fadeIn: {
    opacity: '1',
    height: '3.571rem',
    animation: '$fadeInAnimation 0.2s ease-in'
  }
})

type Props = {
  errors: Error | Response | boolean | undefined
  errorMessageId: string
}

export const Label: React.FC<Props> = ({
  children,
  errors,
  errorMessageId
}) => {
  const classes = useStyles()
  const [showError, setShowError] = useState(true)

  useEffect(() => setShowError(true), [errors])

  return (
    <label>
      {errors && (
        <Loca
          as="div"
          id={errorMessageId}
          className={classnames(
            classes.error,
            showError ? classes.fadeIn : classes.fadeOut
          )}
          onClick={() => setShowError(false)}
        />
      )}
      {children}
    </label>
  )
}
