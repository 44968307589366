import React from 'react'
import { createUseStyles } from 'react-jss'
import { Loca } from '../../intl'
import { checkboxAnimation } from '../../validation'

const useStyles = createUseStyles({
  '@keyframes shakeCheckbox': checkboxAnimation,
  formArea: {
    background: 'linear-gradient(180deg, #042142 0%, #010913 57%)',
    color: '#c6c6c6',
    width: '22.857rem',
    display: 'flex',
    flexDirection: 'column',
    padding: '1.929rem 0.857rem 0',
    '@media (min-width: 755px)': {
      width: '28.571rem',
      padding: '2.5rem 2.857rem 0'
    },
    '& form, & form label': {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      zIndex: '9'
    },
    '& form label': {
      '&.inline': {
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: '1.071rem',
        marginTop: '1.429rem',
        '& input': {
          margin: '0 0.714rem 0 0'
        }
      }
    },
    '& button': {
      background: 'linear-gradient(180deg, #00b7ff 0%, #00a3ff 100%)',
      border: '0.071rem #00c4ff solid',
      color: '#fff',
      minHeight: '2.857rem',
      outline: 'none',
      marginBottom: '2.143rem',
      width: '100%',
      overflowWrap: 'break-word',
      cursor: 'pointer',
      '&:hover': {
        backgroundImage: 'linear-gradient(180deg, #40C4FF 0%, #40C3FF 100%)',
        borderColor: 'rgba(157,252,255,0.40)'
      },
      '&:active:not(:disabled)': {
        backgroundImage: 'linear-gradient(180deg, #006FA8 0%, #007DBE 100%)',
        borderColor: 'rgba(255,255,255,0.15)',
        transform: 'scale(0.95, 0.95)'
      },
      '&:disabled': {
        backgroundImage: 'linear-gradient(180deg, #50A3C4 0%, #719EB8 100%)',
        borderColor: 'rgba(0,248,255,0.40)'
      }
    },
    '& label input': {
      backgroundColor: 'transparent',
      borderWidth: 0,
      color: '#fff',
      outline: 0
    },
    '& label > input': {
      display: 'block'
    },
    '& label > input, label > [role="input"], label div > [role="input"]': {
      backgroundColor: '#273748',
      borderWidth: '0 0 0.143rem 0',
      borderColor: '#00b7ff',
      borderStyle: 'solid',
      height: '2.5rem',
      outline: '0',
      margin: '1rem 0 2.143rem',
      display: 'flex',
      '& > input': {
        flex: '1 0'
      },
      '&[type=number]': {
        '-moz-appearance': 'textfield'
      },
      '&[type=checkbox]': {
        appearance: 'none',
        backgroundColor: 'transparent',
        border: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '&:after': {
          content: '""',
          display: 'block',
          height: '1rem',
          width: '1rem',
          lineHeight: '1rem',
          fontSize: '1rem',
          color: '#000',
          textAlign: 'center',
          borderRadius: '0.143rem',
          border: '0.071rem #00b7ff solid'
        },
        '&.error:after': {
          animation: '$shakeCheckbox 2.2s ease'
        },
        '&:checked:after': {
          content: '"✔"',
          color: '#000',
          backgroundColor: '#00b7ff'
        }
      }
    },
    '& input+small, & [role="input"]+small': {
      position: 'relative',
      height: 0,
      top: '-2rem',
      '&.note': {
        textAlign: 'right'
      }
    },
    '& .separator': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '&:before, &:after': {
        content: '""',
        borderBottom: '0.071rem #262d36 solid',
        display: 'block',
        height: '0.071rem',
        flexGrow: 1
      },
      '&:before': {
        marginRight: '2.857rem'
      },
      '&:after': {
        marginLeft: '2.857rem'
      }
    },
    '& button[type=submit] + p': {
      textAlign: 'center'
    }
  },
  copyright: {
    display: 'block',
    width: '100%',
    fontSize: '0.714rem',
    textAlign: 'center',
    marginTop: 'auto',
    paddingBottom: '1.643rem'
  }
})

export const Area: React.FC = ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <div className={classes.formArea} {...props}>
      {children}

      <div className={classes.copyright}>
        <Loca
          as="small"
          id="Gameforge.CopyRights"
          values={{ year: new Date().getFullYear() }}
        />
      </div>
    </div>
  )
}
