import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { useHistory, useLocation } from 'react-router-dom'
import { plugins } from '@gameforge/http-client'
import { apiClient } from '../../apiClient'
import { BackLink, SupportLink, Form } from '../../components'
import { Loca } from '../../intl'
import useErrorState from '../../utils'
import { Credentials } from './useStateCredentials'

const useStyles = createUseStyles({
  contactSupport: {
    textAlign: 'center'
  }
})

export const RemoveTwoFactorAuth: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      code: ''
    }
  })

  const [busy, setBusy] = useState<boolean>()
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation<Credentials>()
  const { email, password } = location.state
  const { errorHandler, apiClientError, setApiClientError } = useErrorState(
    'TwoFactorAuthentication.Recover'
  )

  const removeTwoFactorAuth = handleSubmit(({ code }: { code: string }) => {
    setBusy(true)

    void apiClient
      .use(plugins.basicAuth({ user: email, password }))
      .delete(`/users/me/devices/${code.toUpperCase()}`)
      .then(() => history.push('/sign-in', location.state))
      .catch(errorHandler)
      .finally(() => {
        setApiClientError(undefined)
        setBusy(false)
      })
  })

  return (
    <>
      <BackLink />

      <Loca as="h1" id="TwoFactorAuthentication.Recover.Title" />

      <Loca as="p" id="TwoFactorAuthentication.Recover.Description" />

      <form onSubmit={removeTwoFactorAuth}>
        <label>
          <Loca id="TwoFactorAuthentication.Recover.RecoveryCode" />
          <Form.CodeInput
            length={10}
            autoFocus
            inputsize={1}
            name="code"
            alphanumeric="both"
            ref={register({
              required: true,
              minLength: 10
            })}
          />
          <Form.Error
            error={{ input: errors.code?.type, api: apiClientError }}
            component="TwoFactorAuthentication.Recover"
          />
        </label>

        <Loca
          as="button"
          type="submit"
          id="TwoFactorAuthentication.Recover.Submit"
          disabled={busy}
        />

        <Loca
          as="p"
          className={classes.contactSupport}
          id="TwoFactorAuthentication.Recover.LostRecoveryCode"
          links={{ support: SupportLink }}
        />
      </form>
    </>
  )
}
