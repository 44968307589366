import React from 'react'
import { useForm } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { BackLink, Form } from '../../components'
import { Loca } from '../../intl'
import { Credentials } from './useStateCredentials'

const useStyles = createUseStyles({
  lostPhone: {
    textAlign: 'center'
  }
})

export const TwoFactorCode: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      code: ''
    }
  })

  const classes = useStyles()
  const history = useHistory()
  const {
    state: { error, ...state }
  } = useLocation<Credentials & { error?: boolean }>()

  const setCode = handleSubmit(({ code }: { code: string }) => {
    history.push('/sign-in', { ...state, code })
  })

  return (
    <>
      <BackLink />

      <Loca as="h1" id="TwoFactorAuthentication.Code.Title" />

      <Loca as="p" id="TwoFactorAuthentication.Code.Description" />

      <form onSubmit={setCode}>
        <Form.Label
          errors={!!error}
          errorMessageId={'TwoFactorAuthentication.Code.InvalidCode'}
        >
          <Loca id="TwoFactorAuthentication.Code.Code" />
          <Form.CodeInput
            length={6}
            autoFocus
            inputsize={3}
            name="code"
            alphanumeric="numbers"
            ref={register({
              required: true,
              minLength: 6
            })}
          />
          <Form.Error
            error={{ input: errors.code?.type }}
            component="TwoFactorAuthentication.Recover"
          />
        </Form.Label>

        <Loca as="button" type="submit" id="Generic.Next" />

        <p className={classes.lostPhone}>
          <Loca
            as={Link}
            to={{
              pathname: '/sign-in/remove-two-factor-auth',
              state
            }}
            id="TwoFactorAuthentication.Code.LostDevice"
          />
        </p>
      </form>
    </>
  )
}
