import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { Link, useHistory } from 'react-router-dom'
import { HttpClientResponse } from '@gameforge/http-client'
import { useBlackbox } from '../../blackbox'
import { apiClient } from '../../apiClient'
import * as Components from '../../components'
import { useContext } from '../../context'
import * as ExternalAuth from '../../externalAuth'
import { Loca } from '../../intl'
import { useState as useRefState } from '../../utils'
import { useSignIn } from '../signIn/useSignIn'

type SignUpType = {
  email: string
  password: string
  checkbox: boolean
}

const useStyles = createUseStyles({
  signInPrompt: {
    textAlign: 'center'
  }
})

export const SignUp: React.FC = () => {
  const history = useHistory()
  const classes = useStyles()
  const { locale } = useContext()
  const getBlackbox = useBlackbox()
  const [busy, setBusy] = useRefState(false)
  const [error, setError] = useState<Error | HttpClientResponse | undefined>()
  const { signIn } = useSignIn()
  const {
    register,
    formState: { errors },
    handleSubmit,
    clearErrors
  } = useForm<SignUpType>({
    mode: 'onChange',
    defaultValues: {
      email: process.env.SIGN_UP_EMAIL || '',
      password: process.env.SIGN_UP_PASSWORD || '',
      checkbox: false
    }
  })

  const onSubmit = handleSubmit((data: { email: string; password: string }) => {
    setBusy(true)
    setError(undefined)

    const { email, password } = data

    void getBlackbox()
      .then(blackbox =>
        apiClient
          .postJson(`/users`, {
            blackbox,
            email,
            password,
            locale
          })
          .then(() => signIn({ email, password }))
          .catch(async err => {
            if (err instanceof HttpClientResponse && err.status === 409) {
              const body = await err.json<{ errorTypes?: string[] }>()
              if (
                body &&
                Array.isArray(body.errorTypes) &&
                body.errorTypes.includes('USER_EXISTS')
              ) {
                history.push('/sign-in', { email, userExistsError: true })
              }
            }
            setError(err)
          })
      )
      .finally(() => setBusy(false))
  })

  return (
    <>
      <Loca as="h1" id="Generic.SignUp" />

      <form onSubmit={onSubmit}>
        <Components.Form.Label errors={error} errorMessageId="SignUp.Error">
          <Components.Email.Input
            name="email"
            error={errors.email}
            ref={register({
              required: 'Email.Required',
              validate: Components.Email.validate
            })}
          />
        </Components.Form.Label>

        <label>
          <Loca id="Generic.Password" />
          <Components.Password.Input
            name="password"
            error={errors.password}
            ref={register({
              required: true,
              validate: Components.Password.validate
            })}
            showValidationMessage={true}
          />
        </label>

        <label className="inline">
          <input
            data-testid="checkbox"
            name="checkbox"
            type="checkbox"
            className={errors.checkbox && 'error'}
            onAnimationEnd={() => clearErrors('checkbox')}
            ref={register({ required: true })}
          />

          <Loca
            id="SignUp.TermsAndConditions"
            links={{
              'terms-and-conditions': Components.TermsAndConditionsLink,
              'privacy-policy': Components.PrivacyPolicyLink
            }}
          />
        </label>

        <Loca as="button" type="submit" disabled={busy} id="Generic.SignUp" />

        <Loca as="div" className="separator" id="SignUp.SignUpWith" />

        <ExternalAuth.Buttons origin="signup" />

        <p className={classes.signInPrompt}>
          <Loca id="SignUp.QueryHaveAccount" />{' '}
          <Loca as={Link} to="/sign-in" id="Generic.SignIn" />
        </p>
      </form>
    </>
  )
}
