import React from 'react'

type ContextType = {
  gameId: string
  gameEnvironmentId: string
  gameImageUrl?: string
  locale: string
  close: (success?: boolean, redirect?: string) => void
}

const defaultValue = {} as ContextType

export const Context = React.createContext<ContextType>(defaultValue)
export const useContext = () => React.useContext(Context)
