import React from 'react'

export const PasswordSuccess = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="160px"
    height="120px"
    viewBox="0 0 160 120"
    version="1.1"
  >
    <defs>
      <filter id="filter-1">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.110299 0 0 0 0 0.957656 0 0 0 0 0.000000 0 0 0 1.000000 0"
        />
      </filter>
      <polygon
        id="path-2"
        points="11.25 20.2125 6.0375 15 4.2625 16.7625 11.25 23.75 26.25 8.75 24.4875 6.9875"
      />
    </defs>
    <g
      id="password-success"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g transform="translate(1.000000, 22.000000)">
        <g
          id="ic/check"
          transform="translate(94.000000, 32.117626)"
          filter="url(#filter-1)"
        >
          <g>
            <mask id="mask-3" fill="white">
              <use xlinkHref="#path-2" />
            </mask>
            <g id="Path" fillRule="nonzero" />
            <g id="Group" mask="url(#mask-3)" fill="#373737">
              <g id="🎨-Color">
                <rect id="Shape" x="0" y="0" width="30" height="30" />
              </g>
            </g>
          </g>
        </g>
        <circle id="Oval" fill="#50E3C2" cx="15" cy="9.11762616" r="7" />
        <circle id="Oval" fill="#5A86C7" cx="146" cy="68.1176262" r="2" />
        <circle id="Oval" fill="#50E3C2" cx="154.5" cy="61.6176262" r="3.5" />
        <circle
          id="Oval"
          fillOpacity="0.8"
          fill="#71A4F1"
          cx="19"
          cy="63.1176262"
          r="2"
        />
        <circle id="Oval" fill="#00C5CC" cx="4" cy="26.1176262" r="4" />
        <circle
          id="Oval"
          fillOpacity="0.7"
          fill="#00C5CC"
          cx="59"
          cy="10.1176262"
          r="4"
        />
        <circle
          id="Oval"
          fillOpacity="0.7"
          fill="#00C5CC"
          cx="73"
          cy="19.1176262"
          r="3"
        />
        <circle id="Oval" fill="#20B3D9" cx="145" cy="52.1176262" r="3" />
        <circle id="Oval" fill="#20B3D9" cx="6" cy="49.1176262" r="3" />
        <circle id="Oval" fill="#61BCE7" cx="40" cy="6.11762616" r="4" />
        <path
          d="M108.81957,2.00304283 C124.542865,2.00304283 128.333333,9.29572575 128.333333,13.5030428 L128.333,24.2000428 L136.4,24.2009595 C137.283656,24.2009595 138,24.9173038 138,25.8009594 L138,67.5176263 C138,68.4012818 137.283656,69.1176262 136.4,69.1176262 L81.5999999,69.1176262 C80.7163444,69.1176262 80,68.4012818 80,67.5176263 L80,25.8009594 C80,24.9173038 80.7163444,24.2009595 81.5999999,24.2009595 L89.666,24.2000428 L89.6666667,13.5030428 C89.6666667,9.29572575 93.0962752,2.00304283 108.81957,2.00304283 Z M130.983334,29.6072095 L87.0166666,29.6072095 C86.182103,29.6072095 85.4967769,30.246171 85.4232053,31.0615768 L85.4166667,31.2072094 L85.4166667,63.1113766 C85.4166667,63.9459402 86.0556281,64.6312663 86.871034,64.7048378 L87.0166666,64.7113765 L130.983334,64.7113765 C131.817897,64.7113765 132.503223,64.072415 132.576795,63.2570092 L132.583334,63.1113766 L132.583334,31.2072094 C132.583334,30.3235538 131.866989,29.6072095 130.983334,29.6072095 Z M108.971122,5.47092477 C96.9122339,5.47092477 94.1735415,11.6674452 94.1121762,15.3437187 L94.1111111,15.4709248 L94.11,24.2000428 L124.11,24.2000428 L124.111111,15.4709248 C124.111111,11.8549293 121.238226,5.61803791 109.392927,5.47348284 L108.971122,5.47092477 Z"
          id="Combined-Shape"
          fill="#00B7FF"
        />
        <path
          d="M27,26 L135,26 C137.209139,26 139,27.790861 139,30 L139,67 C139,69.209139 137.209139,71 135,71 L27,71 C24.790861,71 23,69.209139 23,67 L23,30 C23,27.790861 24.790861,26 27,26 Z"
          id="Rectangle"
          stroke="#00B7FF"
          strokeDasharray="2"
        />
        <path
          d="M96.9366809,3.37056952 C101.679385,1.0623231 106.700492,-0.0612000687 112,1.95672471e-14 C117.799494,0.0669740291 122.992749,2.05051208 127.579765,5.95061416 C129.039262,7.1915468 129.926394,8.9764221 130.034338,10.8891148 C130.678113,22.2963716 131,28 131,28 C120.666667,28 110.333333,28 100,28 C96.1340068,28 93,24.8659932 93,21 L93,9.66470544 C92.9999949,6.98629389 94.528356,4.54268713 96.9366809,3.37056952 Z"
          id="Rectangle"
          stroke="#00B7FF"
          strokeDasharray="2"
        />
      </g>
    </g>
  </svg>
)
