import { MouseEvent, useCallback, useEffect } from 'react'
import { User } from '../../types'
import { apiClient } from '../apiClient'
import { useState } from '../utils'

export const useSession = () => {
  const [session, setSession] = useState<User | false | undefined>(undefined)

  useEffect(() => {
    void apiClient
      .get('/users/me')
      .then(res => res.json<User>())
      .then(session => setSession(session))
      .catch(() => setSession(false))
  }, [])

  const signOut = useCallback((e: MouseEvent) => {
    e.preventDefault()

    return apiClient.delete('/sessions/current').then(() => setSession(false))
  }, [])

  return { session, signOut }
}
