import React from 'react'

export const Close: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg viewBox="0 0 100 100" {...props}>
    <path
      d="M 86.50,99.00
C 86.50,99.00 49.70,62.10 49.70,62.10
  49.70,62.10 13.00,98.90 13.00,98.90
  13.00,98.90 0.70,86.50 0.70,86.50
  0.70,86.50 36.30,49.70 36.30,49.70
  36.30,49.70 0.90,13.20 0.90,13.20
  0.90,13.20 13.30,0.80 13.30,0.80
  13.30,0.80 49.70,37.30 49.70,37.30
  49.70,37.30 86.30,0.70 86.30,0.70
  86.30,0.70 98.70,13.10 98.70,13.10
  98.70,13.10 63.10,49.70 63.10,49.70
  63.10,49.70 98.90,86.60 98.90,86.60
  98.90,86.60 86.50,99.00 86.50,99.00 Z"
    />
  </svg>
)
