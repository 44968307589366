import { useEffect } from 'react'
import { request } from '@gameforge/http-client'
import { useState } from '../utils'

export type Messages = Record<string, string>

const loadTranslations = async (locale: string) =>
  request(window.origin)
    .get(`/translations/${locale}.json`)
    .then(res => res.json<Messages>())

export const useMessages = (locale: string) => {
  const [messages, setMessages] = useState<Messages>()

  useEffect(() => {
    void loadTranslations(locale).then(setMessages)
  }, [locale])

  return messages
}
