import React from 'react'

export const Facebook: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="1000px"
    height="1000px"
    viewBox="0 0 1000 1000"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect stroke="#5375BA" fill="#37599E" width="1000" height="1000"></rect>
      <g transform="translate(421, 212)" fill="#FFFFFF" fillRule="nonzero">
        <path d="M236.604492,701 L236.604492,381.957031 L344.160645,381.957031 L360.273926,257.079102 L236.604492,257.079102 L236.604492,177.543945 C236.604492,141.474365 246.618896,116.893555 298.342529,116.893555 L363.899414,116.893555 L363.899414,5.50244141 C352.491211,3.98779297 313.360107,0.595947266 267.832031,0.595947266 C172.763672,0.595947266 107.698242,58.6037598 107.698242,165.177002 L107.698242,257.079102 L0.544921875,257.079102 L0.544921875,381.957031 L107.698242,381.957031 L107.698242,701 L236.604492,701 Z"></path>
        <path d="M236.604492,701 L236.604492,381.957031 L344.160645,381.957031 L360.273926,257.079102 L236.604492,257.079102 L236.604492,177.543945 C236.604492,141.474365 246.618896,116.893555 298.342529,116.893555 L363.899414,116.893555 L363.899414,5.50244141 C352.491211,3.98779297 313.360107,0.595947266 267.832031,0.595947266 C172.763672,0.595947266 107.698242,58.6037598 107.698242,165.177002 L107.698242,257.079102 L0.544921875,257.079102 L0.544921875,381.957031 L107.698242,381.957031 L107.698242,701 L236.604492,701 Z"></path>
      </g>
    </g>
  </svg>
)
