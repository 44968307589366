import React from 'react'
import { useContext } from '../context'

export const PrivacyPolicyLink: React.FC = props => {
  const { locale } = useContext()

  const localeWithoutDash = locale.replace('-', '')
  const privacyPolicyUrl = `https://agbserver.gameforge.com/${localeWithoutDash}-Privacy-GF-Portal.html`

  return <a {...props} href={privacyPolicyUrl} target="_blank" rel="noopener" />
}
