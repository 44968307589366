import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { apiClient } from '../../apiClient'
import { useContext } from '../../context'
import { dispatch } from '../../events'
import { Loca } from '../../intl'
import { useExternalAuth } from '../Provider'
import { openPopup } from '../utils'

const useStyles = createUseStyles({
  cancelLink: {
    textAlign: 'center',
    '& > a': {
      fontSize: '1.286rem',
      fontWeight: 'bold'
    }
  }
})

export const ShowPopup: React.FC = () => {
  const history = useHistory()
  const classes = useStyles()
  const { provider } = useExternalAuthPopup()

  return (
    <>
      <Loca
        as="h1"
        id="ExternalAuthentication.InProgress.Title"
        values={{ provider }}
      />

      <Loca
        as="p"
        id="ExternalAuthentication.InProgress.Description"
        values={{ provider }}
      />

      <p className={classes.cancelLink}>
        <a onClick={history.goBack}>Cancel</a>
      </p>
    </>
  )
}

function useExternalAuthPopup() {
  const { locale } = useContext()
  const { setError, setProvider } = useExternalAuth()
  const history = useHistory()
  const { provider } = useRouteMatch<{ provider: string }>().params

  useEffect(() => setProvider(provider), [provider])

  useEffect(() => {
    const params = new URLSearchParams({ provider, locale, origin })
    const url = `/api/sessions/external-auth?${params.toString()}`
    const closePopup = openPopup(url, () => history.goBack())

    if (!closePopup) {
      setError('Could not open popup')
      history.goBack()

      return
    }

    const handleMessage = async (message: AuthMessageData) => {
      const { status, code: authCode, email: suggestedEmail } = message.payload

      if (status !== 'success') throw new Error('Failed')

      const res = await apiClient.putJson('/sessions/current', { authCode })

      if (res.status === 201) return history.push('/post-authentication')

      const { token } = await res.json<{ token: string }>()

      history.push('/external-auth/confirm-email', { suggestedEmail, token })
    }

    const listener = (e: MessageEvent<AuthMessageData>) => {
      if (e.data.type !== 'com.gameforge.external-auth') return

      if (e.data.payload.userCreated && e.data.payload.userId)
        dispatch('GFUserSignUp', { userId: e.data.payload.userId })

      closePopup()
      window.removeEventListener('message', listener)

      void handleMessage(e.data).catch(() => {
        setError('Could not complete external authentication')

        history.goBack()
      })
    }

    window.addEventListener('message', listener)

    return () => {
      closePopup()
      window.removeEventListener('message', listener)
    }
  }, [locale, provider])

  return { provider }
}

type AuthMessageData = {
  type: 'com.gameforge.external-auth'
  payload: {
    code: string
    email: string
    status: string
    userCreated: boolean
    userId?: string
  }
}
