import React, { useState, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { CodeInputComponent } from './CodeInputComponent'

const useStyles = createUseStyles({
  code: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    '& > input': {
      textAlign: 'center'
    },
    '& > input[type="number"]': {
      width: '3.5rem'
    },
    '& > input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button':
      {
        '-webkit-appearance': 'none',
        margin: '0'
      },
    '@media (max-width: 755px)': {
      '& > input[type="number"]': {
        width: '3.2rem'
      },
      '& > input[type="text"]': {
        width: '1.8rem'
      }
    }
  }
})

type CodeInputProps = {
  length: number
  autoFocus?: boolean
  disabled?: boolean
  inputsize: number
  alphanumeric?: 'letters' | 'numbers' | 'both'
}

export const CodeInput = React.forwardRef<
  HTMLInputElement,
  React.HTMLProps<HTMLInputElement> & CodeInputProps
>(({ ...props }, ref) => {
  const classes = useStyles()

  const { length, autoFocus, disabled, inputsize, alphanumeric, ...rest } =
    props

  const [activeInput, setActiveInput] = useState(0)
  const [codeValues, setCodeValues] = useState(Array<string>(length).fill(''))

  const changeCodeAtFocus = useCallback(
    (str: string) => {
      const updatedCodeValues = [...codeValues]
      updatedCodeValues[activeInput] = str[0] || ''
      setCodeValues(updatedCodeValues)
    },
    [activeInput, setCodeValues]
  )

  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0)
      setActiveInput(selectedIndex)
    },
    [length]
  )

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1)
  }, [activeInput, focusInput])

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1)
  }, [activeInput, focusInput])

  const handleOnFocus = useCallback(
    (index: number) => () => {
      focusInput(index)
    },
    [focusInput]
  )

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.currentTarget.value
      changeCodeAtFocus(val)
      focusNextInput()
    },
    [changeCodeAtFocus, focusNextInput]
  )

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const pressedKey = e.key

      switch (pressedKey) {
        case 'Backspace':
        case 'Delete': {
          e.preventDefault()
          if (codeValues[activeInput]) {
            changeCodeAtFocus('')
          } else {
            focusPrevInput()
          }
          break
        }
        case 'ArrowLeft': {
          e.preventDefault()
          focusPrevInput()
          break
        }
        case 'ArrowRight': {
          e.preventDefault()
          focusNextInput()
          break
        }
        case 'ArrowDown': {
          e.preventDefault()
          break
        }
        case 'ArrowUp': {
          e.preventDefault()
          break
        }
        default: {
          if (alphanumeric === 'both' && /^[^a-z0-9]$/.exec(pressedKey))
            e.preventDefault()

          if (alphanumeric === 'numbers' && /^[^0-9]$/.exec(pressedKey))
            e.preventDefault()

          break
        }
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, codeValues]
  )

  return (
    <>
      <div {...rest} className={classes.code}>
        {[...Array(length).keys()].map((index: number) => (
          <CodeInputComponent
            size={inputsize}
            type={alphanumeric === 'numbers' ? 'number' : 'text'}
            value={codeValues && codeValues[index].toUpperCase()}
            key={index}
            autoFocus={autoFocus}
            focus={activeInput === index}
            onFocus={handleOnFocus(index)}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            disabled={disabled}
          />
        ))}
        <input
          hidden
          ref={ref}
          type="text"
          value={codeValues.join('')}
          data-testid="hidden-code"
          readOnly
          {...props}
        />
      </div>
    </>
  )
})
