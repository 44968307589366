import { Plugin } from '@gameforge/http-client'

export const eventBridge: Plugin = () => ({
  async report(_, res) {
    const header = (await res).headers.get('gf-events')

    if (header) {
      const events = JSON.parse(header) as [type: string, detail?: unknown][]

      events.forEach(([type, detail]) =>
        window.dispatchEvent(new CustomEvent(type, { detail }))
      )
    }
  }
})
