import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { HttpClientResponse } from '@gameforge/http-client'
import { apiClient } from '../../apiClient'
import { useBlackbox } from '../../blackbox'
import { useContext } from '../../context'
import { useState } from '../../utils'
import { useStateCredentials, Credentials } from './useStateCredentials'

export const useSignIn = (skipPostAuthenticate = false) => {
  const [busy, setBusy] = useState(false)
  const [error, setError] = useState<Error | Response>()
  const { locale, close } = useContext()
  const getBlackbox = useBlackbox()
  const credentials = useStateCredentials()
  const history = useHistory()
  const postAuthentication = () => history.push('/post-authentication')
  const noop = () => ({})

  const signIn = useCallback(
    async (args: Credentials) => {
      setBusy(true)

      const errorHandler = async (err: unknown) => {
        if (err instanceof HttpClientResponse) {
          const { errorTypes } = await err.json<{ errorTypes: string[] }>()

          if (err.status === 403 && errorTypes.includes('USER_BANNED')) {
            return history.push('/banned')
          }

          if (err.status === 409 && errorTypes.includes('OTP_REQUIRED')) {
            return history.push('/sign-in/enter-code', {
              ...args,
              error: errorTypes.includes('OTP_INVALID')
            })
          }
        }

        throw err
      }

      const { code, ...rest } = args

      const headers: Record<string, string> = {}

      if (code) headers['gf-2fa-code'] = code

      return apiClient
        .postJson(
          '/sessions',
          { ...rest, locale, blackbox: await getBlackbox() },
          { headers }
        )
        .then(skipPostAuthenticate ? noop : postAuthentication)
        .catch(errorHandler)
        .catch(setError)
        .finally(() => setBusy(false))
    },
    [history, locale, close, skipPostAuthenticate]
  )

  useEffect(() => {
    if (error) console.error(error)
  }, [error])

  useEffect(() => {
    if (credentials) void signIn(credentials)
  }, [credentials, signIn])

  return { signIn, busy, error }
}
