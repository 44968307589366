import React from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory } from 'react-router-dom'
import { Loca } from '../../intl'
import { PasswordSuccess } from './assets/PasswordSuccess'

const useStyles = createUseStyles({
  center: {
    textAlign: 'center',
    margin: '0 auto',
    '& a': {
      textDecoration: 'underline'
    }
  }
})

export const Success: React.FC = () => {
  const history = useHistory()
  const classes = useStyles()
  const postAuthentication = () => history.push('/post-authentication')

  return (
    <div className={classes.center}>
      <Loca as="h1" id="PasswordReset.Success.Title" />

      <Loca as="p" id="PasswordReset.Success.Description" />

      <PasswordSuccess />

      <p>
        <Loca
          as="a"
          role="link"
          onClick={postAuthentication}
          id="PasswordReset.Success.Continue"
        />
      </p>
    </div>
  )
}
