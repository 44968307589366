import React from 'react'

export const Google: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="1000px"
    height="1000px"
    viewBox="0 0 1000 1000"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect stroke="#F86E62" fill="#DE5246" width="1000" height="1000"></rect>
      <g transform="translate(88, 89)" fill="#fff">
        <g transform="translate(414, 327)">
          <path d="M401.127866,0 C406.942096,27.5847363 410,56.1852622 410,85.5 C410,99.9803282 409.25388,114.286391 407.797988,128.38184 C397.047285,232.466463 347.594501,325.066874 274.074873,391.547839 L138.953721,282.492236 C177.736113,254.594654 207.945369,215.527342 224.871788,170.000002 L0,170 L0,0 L401.127866,0 Z"></path>
        </g>
        <g transform="translate(45, 491)">
          <path d="M367.5,163 C420.226847,163 469.023348,146.172188 508.81187,117.594198 L644.043154,226.576517 C570.882146,292.719493 473.895081,333 367.5,333 C207.217512,333 68.2864622,241.583628 0.00150003598,108.045534 L138.507125,0.503535808 C171.561812,95.1231232 261.604159,163 367.5,163 Z"></path>
        </g>
        <g transform="translate(0, 220)">
          <path d="M46.7716426,0.532421627 L185.166686,106.896588 C175.359806,133.237387 170,161.742769 170,191.5 C170,220.630974 175.13658,248.562235 184.55343,274.437473 L45.285694,379.600267 C16.3365494,323.198419 0,259.257012 0,191.5 C0,122.592175 16.896195,57.6306684 46.7716426,0.532421627 Z"></path>
        </g>
        <g transform="translate(45, 0)">
          <path d="M366.5,0 C480.75747,0 584.165078,46.4537777 658.866366,121.504873 L539.09514,242.155691 C495.125567,197.608779 434.036971,170 366.5,170 C262.328557,170 173.498498,235.684274 139.167059,327.895585 L0.772561965,221.530664 C69.6692029,89.8562782 207.591072,0 366.5,0 Z"></path>
        </g>
      </g>
    </g>
  </svg>
)
