import React from 'react'

export const SupportLink: React.FC = props => {
  return (
    <a
      {...props}
      href="https://support.gameforge.com/"
      target="_blank"
      rel="noopener"
    />
  )
}
