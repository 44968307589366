import React, { useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useLocation } from 'react-router-dom'
import { useCountdown } from '../../../utils'
import { apiClient } from '../../apiClient'
import { useContext } from '../../context'
import { Loca } from '../../intl'

const useStyles = createUseStyles({
  resend: {
    textAlign: 'center'
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: '0.5',
    textDecoration: 'none',
    textAlign: 'center'
  }
})

export const ResendCodeLink: React.FC = () => {
  const location = useLocation<{ email: string }>()
  const { locale } = useContext()
  const { email } = location.state
  const classes = useStyles()

  const [busy, setBusy] = useState(false)
  const [countdown, startCountDown] = useCountdown(30)

  const resendCode = useCallback(() => {
    if (busy || countdown > 0) return
    setBusy(true)
    apiClient
      .post(`/users/${email}/password?locale=${locale}`)
      .then(() => startCountDown())
      .catch(() => console.error('Send password code request failed'))
      .finally(() => setBusy(false))
  }, [busy, countdown, email, locale, startCountDown, setBusy])

  return (
    <Loca
      className={countdown > 0 ? classes.disabled : classes.resend}
      as="a"
      id="PasswordReset.Code.Resend"
      role="link"
      onClick={resendCode}
      values={{ cooldownSeconds: countdown }}
    />
  )
}
