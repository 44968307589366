import { useState, useCallback } from 'react'
import { HttpClientResponse } from '@gameforge/http-client'

type Component = 'TwoFactorAuthentication.Recover' | 'ResetPassword'

export default (component: Component) => {
  const [apiClientError, setApiClientError] = useState<string | undefined>(
    undefined
  )

  const errorHandler = useCallback(
    async (err: unknown) => {
      if (!(err instanceof HttpClientResponse) || err.status !== 404) {
        setApiClientError(`${component}.UnexpectedError`)

        return
      }

      const body = await err
        .json<{ errorTypes?: string[]; resourceType?: string }>()
        .catch(() => undefined)

      if (
        !body?.errorTypes?.includes('RESOURCE_NOT_FOUND') ||
        body.resourceType !== 'code'
      ) {
        setApiClientError(`${component}.UnexpectedError`)
      }

      setApiClientError(`${component}.InvalidCode`)
    },
    [apiClientError, component]
  )

  return { apiClientError, errorHandler, setApiClientError }
}
