import React from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useHistory } from 'react-router-dom'
import { HttpClientResponse } from '@gameforge/http-client'
import { Email } from '../../components'
import { useContext } from '../../context'
import { Loca } from '../../intl'
import { useState } from '../../utils'
import { ReturnLink } from '../components/ReturnLink'
import { useExternalAuth } from '../Provider'

export const ConfirmEmail: React.FC = () => {
  const { close } = useContext()
  const [busy, setBusy] = useState(false)
  const history = useHistory()
  const location = useLocation<{ suggestedEmail?: string; token: string }>()
  const { completeUser, provider } = useExternalAuth()
  const { suggestedEmail } = location.state || {}

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: { email: suggestedEmail }
  })

  const onSubmit = ({ email }: { email: string }) => {
    setBusy(true)

    const errorHandler = async (err: unknown) => {
      if (!(err instanceof HttpClientResponse)) return
      const { errorTypes } = await err.json<{ errorTypes: string[] }>()

      if (err.status === 403 && errorTypes.includes('NO_GHOST_USER'))
        return close(true)

      if (err.status === 409 && errorTypes.includes('USER_EXISTS'))
        return history.push('/external-auth/sign-in')

      if (err.status === 409 && errorTypes.includes('PASSWORD_REQUIRED'))
        return history.push('/external-auth/sign-up')
    }

    void completeUser({ email })
      .catch(errorHandler)
      .finally(() => setBusy(false))
  }

  return (
    <>
      <Loca as="h1" id="ExternalAuthentication.ConfirmEmail.Title" />

      <Loca
        as="p"
        id="ExternalAuthentication.ConfirmEmail.Description"
        values={{ provider }}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>
          <Email.Input
            name="email"
            error={errors.email}
            ref={register({
              required: 'Email.Required',
              validate: Email.validate
            })}
          />
        </label>

        <Loca id="Generic.Next" as="button" type="submit" disabled={busy} />
      </form>

      <ReturnLink />
    </>
  )
}
