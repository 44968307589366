import React from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { apiClient } from '../../apiClient'
import { BackLink, Form } from '../../components'
import { Loca } from '../../intl'
import useErrorState, { useState } from '../../utils'
import { ResendCodeLink } from './ResendCodeLink'

export const EnterCode: React.FC = () => {
  const history = useHistory()
  const location = useLocation<{ email: string }>()
  const { errorHandler, apiClientError, setApiClientError } =
    useErrorState('ResetPassword')

  const [busy, setBusy] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      code: process.env.CODE || ''
    }
  })

  const { email } = location.state

  const onSubmit = handleSubmit(({ code }: { code: string }) => {
    setBusy(true)

    void apiClient
      .get(`/users/${email}/password/${code}`)
      .then(() =>
        history.push('/reset-password/set-new-password', { email, code })
      )
      .catch(errorHandler)
      .finally(() => {
        setApiClientError(undefined)
        setBusy(false)
      })
  })

  return (
    <>
      <BackLink />

      <Loca as="h1" id="PasswordReset.Code.Title" />

      <form onSubmit={onSubmit}>
        <Loca as="p" id="PasswordReset.Code.Description" />

        <label>
          <Loca id="PasswordReset.Code.Code" />
          <Form.CodeInput
            length={6}
            autoFocus
            name="code"
            alphanumeric="numbers"
            inputsize={3}
            ref={register({
              required: true,
              minLength: 6
            })}
          />
          <Form.Error
            error={{ input: errors.code?.type, api: apiClientError }}
            component="ResetPassword"
          />
        </label>

        <Loca
          as="button"
          type="submit"
          disabled={busy}
          id="PasswordReset.Code.Submit"
        />

        <ResendCodeLink />
      </form>
    </>
  )
}
