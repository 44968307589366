import React, { createContext, useContext, useMemo, useState } from 'react'
import { useLayoutEffect } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import { useCompleteUser } from './hooks/useCompleteUser'
import * as Views from './views'

export type ContextType = {
  email?: string
  error?: string
  origin?: 'signup' | 'signin'
  setError: (err?: string) => void
  provider?: string
  setProvider: (origin: ContextType['provider']) => void
  completeUser: (args: { email?: string; password?: string }) => Promise<void>
}

const Context = createContext({} as ContextType)
export const useExternalAuth = () => useContext(Context)

export const Provider: React.FC = props => {
  const [error, setError] = useState<string>()
  const { completeUser, persistedEmail } = useCompleteUser()
  const [origin, setOrigin] = useState<ContextType['origin']>()
  const [provider, setProvider] = useState<ContextType['provider']>()
  const { state } = useLocation<{ origin?: ContextType['origin'] }>()

  useLayoutEffect(
    () => state?.origin && setOrigin(state.origin),
    [state?.origin]
  )

  const value = useMemo(() => {
    return {
      completeUser,
      email: persistedEmail,
      origin,
      ...{ error, setError },
      ...{ provider, setProvider }
    }
  }, [completeUser, persistedEmail, error, origin, provider])

  return (
    <Context.Provider value={value}>
      <Switch>
        <Route path="/external-auth/start/:provider">
          <Views.ShowPopup />
        </Route>

        <Route path="/external-auth/confirm-email">
          <Views.ConfirmEmail />
        </Route>

        <Route path="/external-auth/sign-in">
          <Views.SignIn />
        </Route>

        <Route path="/external-auth/sign-up">
          <Views.SignUp />
        </Route>

        <Route {...props} />
      </Switch>
    </Context.Provider>
  )
}
