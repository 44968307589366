import { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

export type Credentials = {
  email: string
  password: string
  code?: string
  rememberMe?: boolean
}

export const useStateCredentials = () => {
  const location = useLocation<Credentials>()
  const history = useHistory()
  const [credentials, setCredentials] = useState<Credentials | undefined>()

  useEffect(() => {
    if (!location.state?.email || !location.state.password) return

    const { code, email, password, rememberMe } = location.state

    setCredentials({ code, email, password, rememberMe })
    history.replace(location.pathname, {})
  }, [
    location.pathname,
    location.state?.code,
    location.state?.email,
    location.state?.password,
    location.state?.rememberMe
  ])

  return credentials
}
