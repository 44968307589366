import React from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory } from 'react-router-dom'
import { Loca } from '../intl'

const useStyles = createUseStyles({
  back: {
    marginBottom: '3rem'
  }
})

export const BackLink: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <a role="link" className={classes.back} onClick={history.goBack}>
      ❮ <Loca id="Generic.Back" />
    </a>
  )
}
