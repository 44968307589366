import React, { useCallback, useState } from 'react'
import classnames from 'classnames'
import { FieldError } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { Eye, EyeSlash, Tick } from '../icons'
import { Loca } from '../intl'

const useStyles = createUseStyles({
  passwordInput: {
    '& > svg': {
      width: '1.286rem',
      margin: '0 0.714rem'
    }
  },
  validation: {
    display: 'flex',
    paddingTop: '0.857rem',
    textAlign: 'left',
    minHeight: '2.071rem',
    '& > span': {
      display: 'block'
    },
    '& > svg': {
      height: '1rem',
      width: '1rem',
      margin: '0.143rem 0.571rem 0 0'
    }
  },
  error: { color: '#ea1d36' },
  success: { color: '#3ccfba' },
  basic: { color: '#accaeb' }
})

export const validate = (value: string) =>
  value.length >= 10 && /[A-Z]/.test(value) && /[0-9]/.test(value)

export const Input = React.forwardRef<
  HTMLInputElement,
  React.HTMLProps<HTMLInputElement> & {
    showValidationMessage?: boolean
    error?: FieldError
  }
>(({ showValidationMessage, error, ...props }, ref) => {
  const classes = useStyles()
  const [passwordChanged, setPasswordChanged] = useState(false)
  const [visible, setVisible] = useState(false)
  const toggle = useCallback(() => setVisible(v => !v), [])

  return (
    <>
      <div className={classes.passwordInput} role="input">
        <input
          {...props}
          ref={ref}
          type={visible ? 'text' : 'password'}
          onChange={() => setPasswordChanged(true)}
        />
        {visible ? <Eye onClick={toggle} /> : <EyeSlash onClick={toggle} />}
      </div>
      {showValidationMessage && (
        <small
          className={classnames(
            classes.validation,
            error && classes.error,
            !error && passwordChanged && classes.success,
            !error && !passwordChanged && classes.basic
          )}
        >
          <Tick />
          <Loca id="Generic.PasswordRules" />
        </small>
      )}
    </>
  )
})
