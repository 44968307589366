import { jss } from 'react-jss'
import notoSansBoldWoff from '../assets/fonts/noto-sans-v11-latin-700.woff'
import notoSansBoldWoff2 from '../assets/fonts/noto-sans-v11-latin-700.woff2'
import notoSansRegularWoff from '../assets/fonts/noto-sans-v11-latin-regular.woff'
import notoSansRegularWoff2 from '../assets/fonts/noto-sans-v11-latin-regular.woff2'

const fontFamily = 'NotoSans'

jss
  .createStyleSheet({
    '@global *': { fontFamily, fontSize: '14px' },
    '@global small, small > *': { fontSize: '12px' },
    '@global h1': {
      fontFamily,
      fontWeight: 'bold',
      fontSize: '1.286rem',
      color: '#c6c6c6',
      textTransform: 'uppercase',
      marginBottom: '2.143rem'
    },
    '@global a': {
      color: '#00b7ff',
      cursor: 'pointer'
    }
  })
  .attach()

jss
  .createStyleSheet({
    '@font-face': {
      fontFamily,
      fontWeight: 400,
      fontStyle: 'normal',
      src: [
        `local("")`,
        `url(${notoSansRegularWoff2}) format("woff2")`,
        `url(${notoSansRegularWoff}) format("woff")`
      ].join(',\n')
    }
  })
  .attach()

jss
  .createStyleSheet({
    '@font-face': {
      fontFamily,
      fontWeight: 700,
      fontStyle: 'normal',
      src: [
        `local("")`,
        `url(${notoSansBoldWoff2}) format("woff2")`,
        `url(${notoSansBoldWoff}) format("woff")`
      ].join(',\n')
    }
  })
  .attach()
