import React from 'react'
import { render, unmountComponentAtNode } from 'react-dom'
import { parseLocale } from '@gameforge/loca'
import { Event } from '../types'
import { UI } from './UI'

type ShowMessage = {
  type: 'show'
  origin: string
  payload: {
    gameId: string
    gameEnvironmentId: string
    gameImageUrl?: string
    locale?: string
  }
}

const getLocale = (defaultLocale?: string) =>
  parseLocale(defaultLocale || window.navigator.language || 'en-GB').short

window.addEventListener('message', (e: MessageEvent<ShowMessage>) => {
  const { payload, type } = e.data
  const { origin } = e

  if (type !== 'show') return

  const close = (success?: boolean, redirect?: string) => {
    const app = document.getElementById('root')
    app && unmountComponentAtNode(app)

    parent.postMessage(
      { type: success ? 'success' : 'close', redirect },
      origin
    )
  }

  const events = [
    'GFLandingpageConnectOpen',
    'GFSessionExist',
    'GFUserSignUp',
    'GFUserSignIn',
    'GFUserSignOut',
    'GFGameAccountCreate',
    'GFGameAccountExist'
  ] as Event[]

  events.forEach((eventType: string) =>
    window.addEventListener(eventType, e => {
      const detail = (e as CustomEvent<unknown>).detail

      parent.postMessage({ type: 'event', eventType, detail }, origin)
    })
  )

  const props = {
    ...payload,
    close,
    locale: getLocale(payload.locale)
  }

  render(React.createElement(UI, props), document.getElementById('root'))
})
