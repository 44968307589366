import React from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { apiClient } from '../../apiClient'
import { BackLink, Email } from '../../components'
import { useContext } from '../../context'
import { Loca } from '../../intl'
import { useState } from '../../utils'

export const EnterEmail: React.FC = () => {
  const history = useHistory()
  const [busy, setBusy] = useState(false)
  const { locale } = useContext()
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      email: process.env.RESET_PASSWORD_EMAIL || ''
    }
  })

  const onSubmit = handleSubmit((data: { email: string }) => {
    setBusy(true)

    void apiClient
      .post(`/users/${data.email}/password?locale=${locale}`)
      .then(() => history.push('/reset-password/enter-code', data))
      .catch(() => setBusy(false))
  })

  return (
    <>
      <BackLink />

      <Loca as="h1" id="PasswordReset.EnterEmail.Title" />

      <form onSubmit={onSubmit}>
        <Loca as="p" id="PasswordReset.EnterEmail.Description" />

        <label>
          <Email.Input
            name="email"
            error={errors.email}
            ref={register({
              required: 'Email.Required',
              validate: Email.validate
            })}
          />
        </label>

        <Loca as="button" type="submit" disabled={busy} id="Generic.Next" />
      </form>
    </>
  )
}
