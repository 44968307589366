import React from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { HttpClientResponse } from '@gameforge/http-client'
import { Password } from '../../components'
import { Loca } from '../../intl'
import { useState } from '../../utils'
import { ReturnLink } from '../components/ReturnLink'
import { useExternalAuth } from '../Provider'

export const SignIn: React.FC = () => {
  const [busy, setBusy] = useState(false)
  const [error, setError] = useState<string>()
  const { completeUser, email, provider } = useExternalAuth()
  const { register, handleSubmit } = useForm()
  const history = useHistory()

  const onSubmit = ({ password }: { password: string }) => {
    setBusy(true)
    setError(undefined)

    const errorHandler = async (err: unknown) => {
      if (err instanceof HttpClientResponse) {
        const { errorTypes } = await err.json<{ errorTypes: string[] }>()

        if (err.status === 409 && errorTypes.includes('PASSWORD_INVALID'))
          return setError('Password invalid')

        if (err.status === 409 && errorTypes.includes('OTP_REQUIRED')) {
          return history.push('/sign-in/enter-code', {
            email,
            password
          })
        }
      }

      setError('An unknown error occurred')
    }

    void completeUser({ email, password })
      .catch(errorHandler)
      .finally(() => setBusy(false))
  }

  return (
    <>
      <Loca as="h1" id="ExternalAuthentication.LinkAccount.Title" />

      <Loca
        as="p"
        id="ExternalAuthentication.LinkAccount.Description"
        values={{ provider }}
      />

      <p>
        <a onClick={history.goBack}>❮ {email}</a>
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <label>
          <Loca id="Generic.Password" />
          <Password.Input name="password" ref={register({ required: true })} />
        </label>

        {error && <p>{error}</p>}

        <Loca
          as="button"
          id="ExternalAuthentication.LinkAccount.Submit"
          type="submit"
          disabled={busy}
        />
      </form>

      <ReturnLink />
    </>
  )
}
