import React from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { HttpClientResponse } from '@gameforge/http-client'
import * as Components from '../../components'
import { Loca } from '../../intl'
import { useState } from '../../utils'
import { ReturnLink } from '../components/ReturnLink'
import { useExternalAuth } from '../Provider'

type SignUpType = {
  password: string
  checkbox: boolean
}

export const SignUp: React.FC = () => {
  const [busy, setBusy] = useState(false)
  const [error, setError] = useState<string>()
  const { completeUser, email, provider } = useExternalAuth()
  const history = useHistory()
  const { register, handleSubmit, errors, clearErrors } = useForm<SignUpType>({
    mode: 'onChange',
    defaultValues: {
      password: process.env.SIGN_UP_PASSWORD || '',
      checkbox: false
    }
  })

  const onSubmit = ({ password }: { password: string }) => {
    setBusy(true)
    setError(undefined)

    const errorHandler = async (err: unknown) => {
      if (err instanceof HttpClientResponse) {
        const { errorTypes } = await err.json<{ errorTypes: string[] }>()

        if (err.status === 409 && errorTypes.includes('PASSWORD_INVALID'))
          return setError('Password invalid')
      }

      setError('An unknown error has occurred')
    }

    void completeUser({ password })
      .catch(errorHandler)
      .finally(() => setBusy(false))
  }

  return (
    <>
      <Loca as="h1" id="ExternalAuthentication.CreateAccount.Title" />

      <Loca
        as="p"
        id="ExternalAuthentication.CreateAccount.Description"
        values={{ provider }}
      />

      <p>
        <a onClick={history.goBack}>❮ {email}</a>
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <label>
          <Loca id="Generic.Password" />
          <Components.Password.Input
            name="password"
            error={errors.password}
            ref={register({
              required: true,
              validate: Components.Password.validate
            })}
            showValidationMessage={true}
          />
        </label>

        {error && <p>{error}</p>}

        <label className="inline">
          <input
            data-testid="checkbox"
            name="checkbox"
            type="checkbox"
            className={errors.checkbox && 'error'}
            onAnimationEnd={() => clearErrors('checkbox')}
            ref={register({ required: true })}
          />
          <Loca
            id="SignUp.TermsAndConditions"
            links={{
              'terms-and-conditions': Components.TermsAndConditionsLink,
              'privacy-policy': Components.PrivacyPolicyLink
            }}
          />
        </label>

        <Loca
          as="button"
          id="ExternalAuthentication.LinkAccount.Submit"
          type="submit"
          disabled={busy}
        />
      </form>

      <ReturnLink />
    </>
  )
}
