import { useState, useEffect } from 'react'

type Countdown = [countdown: number, start: () => void]

export default (time: number, interval = 1): Countdown => {
  const [countdown, setCountdown] = useState(0)

  useEffect(() => {
    let ref: NodeJS.Timeout
    if (countdown > 0) {
      ref = setTimeout(() => {
        setCountdown(countdown => Math.max(countdown - interval, 0))
      }, interval * 1000)

      return () => clearTimeout(ref)
    }
  }, [countdown])

  const start = () => !countdown && setCountdown(time)

  return [countdown, start]
}
